.content {
  display: flex;
  background-color: #f6f6f6;
  padding-top: 80px;
  padding-left: 392px;
  min-height: 100vh; }

.containerFluid {
  margin-top: 24px;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;
  // margin-right: auto
 }  // margin-left: auto

.row {
  margin-right: -15px;
  margin-left: -15px; }

.col {
  flex-grow: 1;
  max-width: 100%;
  flex-basis: 0;
  -webkit-box-flex: 1;
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.wrapper {
  position: fixed;
  top: 80px;
  left: 94px;
  width: calc(100% - 94px);
  height: calc(100vh - 80px);
  overflow: auto;

  @media screen and (max-width: 768px) {
    left: 0;
    width: 100%; } }


.profileSection {
  background-color: #E9E9E9; }

.profileLayoutContent {
  max-width: 724px;
  margin-left: 20px;
  margin-top: 32px;
  margin-right: 90px;
  margin-bottom: 16px;
  background-color: #ffffff;
  border-radius: 4px;
  padding: 40px; }
