@import 'theme/vars.scss';

.button {
  display: inline-block;
  position: relative;
  border-radius: 20px;
  border: 2px solid #e6e6e6;
  font-size: 13px;
  padding: 10px 16px;
  color: #b4b4b4;
  background: transparent;
  appearance: none;
  outline: none;
  font-weight: 500;
  margin: 6px;
  transition: all 0.1s;
  user-select: none;
  &:hover {
    cursor: pointer;
    border-color: #d5ede1;
    background-color: #e2f0e9;
    color: $accent_light;
  }
  &:active {
    transform: scale(0.96);
    transition: 0.02s;
    cursor: pointer;
    border-color: #d5ede1;
    background-color: #e2f0e9;
    color: $accent_light;
  }
}

.active {
  border-color: #d5ede1;
  background-color: #e2f0e9;
  color: $accent_light;
  text-shadow: 0 0 $accent_light, 0 0 $accent_light;
}

.buttonCounter {
  display: inline-block;
  position: absolute;
  top: 0px;
  right: -6px;
  transform: translateY(-30%);
  min-width: 20px;
  height: 20px;
  padding: 0 4px;
  color: #fff;
  border-radius: 99px;
  background: #c9302c;
  font-size: 12px;
  line-height: 20px;
}

// use this css if counter badge neeed
// .buttonsBox {
//   padding-top: 10px;
//   div {
//     display: inline-block;
//   }
//   button {
//     min-width: 115px;
//     position: relative;
//     height: 40px;
//     padding: inherit;
//   }
// }
