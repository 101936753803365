@import 'theme/vars.scss';

.delete {
  color: #b4b4b4;
  padding: 0;
  transition: all .25s;
  &:hover {
    color: #c82d3b; }
  &:active {
    transform: scale(1.2); } }

.edit {
  color: #b4b4b4;
  padding: 0;
  margin-left: 8px;
  transition: all .25s;
  &:hover {
    color: $accent; }
  &:active {
    transform: scale(1.2); } }

.row {
  display: flex !important;
  justify-content: space-between;
  align-items: center; }
