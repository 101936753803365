.exportButton {
  color: #ffffff;
  background-color: #4e9cdf;
  padding: 11px 14px;
  height: auto;
  vertical-align: middle;
  font-size: 14px;
  line-height: 16px;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  svg {
    height: 20px;
    width: 20px;
  }

  span {
    margin-left: 10px;
  }

  &:hover,
  &:focus,
  &:active {
    background-color: #4e9cdf;
    color: #fff;
  }
}
